@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import "~antd/dist/antd.less";
@import "./assets/css/maps.less";
@import "./assets/css/vars.less";
@import "./assets/css/media-queries.less";

/************** TAGS **************/
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.talk-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: #ff5f00;
  border-radius: 30px;
  height: 3rem;
  width: 100%;

  span {
    svg {
      width: 1rem;
      height: 1rem;
      margin-top: 0.225rem;
    }
  }
}

// ::-webkit-scrollbar {
//   display: none;
// }

h1 {
  #typography.big-title();
}

h2 {
  #typography.normal-title();
}

h3 {
  #typography.small-title();
}

h4 {
  #typography.subtitle();
}

h5 {
  #typography.body-bold();
}

p,
span {
  #typography.body();
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

/************** END TAGS **************/

/************** CLASS **************/
.disabled-color {
  color: #8c8c8c;
}

.primary-pointer-text {
  color: #ff5300;
  cursor: pointer;
}

.action-table-button {
  color: #ff5300;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: #ff5300;
  border-radius: 50px;
  background-color: rgba(255, 50, 0, 0.08);
}

.ant-drawer-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

/************** END CLASS **************/

/**************  ANTD INPUT **************/

.ant-input {
  border-radius: 8px;
  height: 38px;
  background-color: #f5f5f5;
  border: none;
}

.ant-input::placeholder {
  color: #8c8c8c;
}

.ant-input:focus {
  outline: none;
  box-shadow: none;
}

.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input {
  background-color: #f5f5f5;

  &:hover {
    background-color: #f5f5f5;
  }
}

.ant-input-affix-wrapper-focused {
  box-shadow: none;
}

.ant-input-affix-wrapper > input.ant-input {
  padding: 3px 11px;
}

/************** END ANTD INPUT **************/

/************** ANTD DRAWER **************/
.ant-drawer-content {
  border-radius: 12px 0px 0px 12px;
}

/************** END ANTD DRAWER **************/

/************** ANTD TABLE **************/

.ant-table-column-title,
.ant-table-thead .ant-table-cell {
  #typography.bold-small-paragraph();
}

.ant-table-tbody .ant-table-cell {
  #typography.small-paragraph();
}

.ant-table-cell {
  min-width: 100px;
  max-width: 200px;
}

/************** END ANTD TABLE **************/

// .ant-dropdown {
//   overflow: auto;
// }

@primary-color: #ff5f00;@font-family: Montserrat;@input-height-base: 38px;@line-height-base: 32px;