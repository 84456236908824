@import "../../assets/css/maps.less";
@import "../../assets/css/vars.less";
@import "../../assets/css/media-queries.less";

.gama-button {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 2rem !important;
  height: 2.125rem !important;
  padding: 0.375rem 1.5rem !important;
  gap: 0.625rem !important;
  background-color: @colors[primary];

  @media @desktop {
    padding: 0.5rem 2rem !important;
    gap: 0.5rem !important;
    height: 2.625rem !important;
  }

  span {
    #typography.body-bold();
  }
}

.gama-outline {
  .gama-button();
  color: @colors[primary];
  background: transparent;
}

@primary-color: #ff5f00;@font-family: Montserrat;@input-height-base: 38px;@line-height-base: 32px;