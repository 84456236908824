@import "../../assets/css/media-queries.less";
@import "../../assets/css/vars.less";
@import "../../assets/css/maps.less";
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

.splide__arrow svg {
  fill: #fff;
  height: 1.2em;
  width: 1.2em;
}

.splide__pagination__page.is-active {
  background-color: #ff3200;
  width: 15px;
  border-radius: 100px;
  margin-top: 20px;
}

.splide__pagination__page {
  margin-top: 20px;
}

.home-banner-container {
  position: relative;
  height: 100vh;

  h1 {
    text-align: center;
    width: 100%;
    font-size: 2.5rem;
    color: @colors[primary];

    @media @tablet {
      font-size: 3.5rem;
    }
  }

  h3 {
    text-align: center;
    width: 100%;
    font-size: 1.5rem;
    color: @colors[neutral_1];

    @media @tablet {
      font-size: 2.5rem;
    }
  }
}

.banner-title-container {
  display: flex;
  margin-top: 3rem;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media @desktop {
    padding: 0;
  }
}
.visuallyHidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

h1 {
  text-align: center;
}

.container {
  width: 70%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.hamburger {
  margin: 0 auto;
  width: 30px;
  height: 30px;
  transform: scale(0.7);
}

.hamburger .bar {
  padding: 0;
  width: 30px;
  height: 4px;
  background-color: @colors[primary];
  display: block;
  border-radius: 4px;
  transition: all ease-in-out 0.4s;
  position: absolute;
}

.bar1 {
  top: 0;
}

.bar2,
.bar3 {
  top: 13.5px;
}

.bar3 {
  right: 0;
}

.bar4 {
  bottom: 0;
}

.hamburger4 .bar4 {
  top: 13.5px;
}

.hamburger4 .bar5 {
  bottom: 0px;
}

.hamburger4 .bar {
  transition: all ease-in-out 0.4s, transform 0.4s ease-in-out 0.4s;
}

.hamburger4 .bar2 {
  width: 1px;
  transform: rotate(90deg);
  left: 13.5px;
}

.hamburger4 .bar3 {
  width: 1px;
  left: 13.5px;
}

.checkbox4:checked + label > .hamburger4 > .bar1 {
  top: 13.5px;
  background-color: transparent;
}

.checkbox4:checked + label > .hamburger4 > .bar2 {
  left: 0px;
  width: 30px;
  transform: rotate(45deg);
}

.checkbox4:checked + label > .hamburger4 > .bar3 {
  left: 0;
  width: 30px;
  transform: rotate(-45deg);
}

.checkbox4:checked + label > .hamburger4 > .bar4 {
  background-color: transparent;
}

.checkbox4:checked + label > .hamburger4 > .bar5 {
  bottom: 13.5px;
  background-color: transparent;
}

// MODAL
.modal {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  // background: rgba(0, 0, 0, .5);
  background: @colors[neutral_11];
  transition: all ease-out 1s;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  height: 100vh;
  width: 100%;

  .delimiter {
    height: 4.25rem;
    border-bottom: 1px solid #262626;

    @media @tablet {
      height: 6.25rem;
    }
  }

  .container-modal {
    height: 100%;
    display: flex;
    flex-direction: column;

    .header-modal {
      height: 100%;
      margin: 0 1rem;
      .nav-group {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        & :nth-child(1) {
          margin-top: 1.5rem;
        }

        a {
          color: @colors[neutral_1];
          font-size: 18px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 0.05em;
          text-align: left;
          margin-bottom: 1.5rem;
        }
      }

      .button-group {
        height: 30%;
        display: flex;
        flex-direction: column;

        // height: 30%;

        .talk-button {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: transparent;
          color: @colors[primary];
          border-radius: 30px;
          height: 3rem;
          width: 100%;

          span {
            svg {
              width: 1rem;
              height: 1rem;
              margin-top: 0.225rem;
            }
          }
        }

        .portal-button {
          height: 3rem;
          width: 100%;
          border-radius: 30px;
          margin-bottom: 1.5rem;
        }
      }
    }

    .footer-modal {
      ul {
        margin: 0 1rem;
        margin-top: 1rem;
        li {
          margin-bottom: 1rem;
          a {
            color: @colors[neutral_1];
          }
        }
      }
    }
  }
}

.modal-active {
  opacity: 1;
  pointer-events: auto;
  z-index: -1;
}
.container-footer {
  position: relative;
  background-color: @colors[neutral_11] !important;
  padding: 0 1rem;

  @media @tablet {
    padding: 0;
  }

  .contain {
    // margin-top: 4.5rem;

    @media @tablet {
      padding: 0 8rem;
    }

    @media @desktop {
      display: flex;
      justify-content: space-between;
    }

    h3,
    h5 {
      color: @colors[neutral_1];
      margin: 0;
    }

    a,
    p {
      font-size: 14px;
      margin: 0;
      color: @colors[neutral_6];
    }

    .footer-header {
      // margin-top: 4.5rem;

      .footer-social-networks {
        margin-top: 1rem;

        ul {
          li {
            border-radius: 50%;
            width: 2rem;
            height: 2rem;
            background-color: @colors[secondary];
            display: inline-block;
            margin-right: 0.5rem;

            a {
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;

              svg {
                color: @colors[neutral_1];
              }
            }
          }
        }
      }

      .footer-bh-logo {
        margin-top: 1.5rem;
      }
    }

    .footer-about {
      margin-top: 2.5rem;

      @media @desktop {
        margin-top: 0;
      }
    }

    .footer-information {
      margin-top: 2rem;

      @media @desktop {
        margin-top: 0;
      }
    }

    .footer-callcenter {
      margin-top: 2rem;

      @media @desktop {
        margin-top: 0;
      }

      .phone {
        display: flex;
        align-items: center;

        span {
          margin-right: 0.625;

          svg {
            color: @colors[primary];
          }
        }
      }
    }

    .footer-newsletter {
      margin-top: 2rem;
      margin-bottom: 5.5rem;

      @media @desktop {
        margin: 0;
      }

      form {
        margin-top: 0.5rem;
        display: flex;
        flex-direction: column;

        input {
          border-radius: 0.375rem;
          width: 16rem;
        }

        button {
          margin-top: 1rem;
          width: 8rem;
        }
      }
    }
  }

  .footer-signature {
    position: relative;
    font-size: 12px;
    color: @colors[neutral_5];
    margin-bottom: 4.375rem;

    @media @desktop {
      margin-top: 3.5rem;
    }

    hr {
      border: none;
      border-bottom: 1px solid @colors[neutral_5];
      background-color: transparent;
      @media @tablet {
        position: absolute;
        top: 0;
        width: 100%;
        height: 1px;
      }
    }

    .signature-contain {
      @media @desktop {
        display: flex;
        justify-content: space-between;
        padding: 0 8rem;
      }

      .footer-links {
        margin-top: 1rem;
        @media @desktop {
          margin-top: 1.5rem;
        }
        a {
          color: @colors[neutral_5];
          font-size: 12px;
        }

        .ant-divider {
          border-color: @colors[neutral_5];
        }
      }

      .all-rights-reserved {
        font-size: 12px;

        @media @desktop {
          margin-top: 1.5rem;
        }
      }
    }
  }

  .arrow-up-button {
    position: absolute;
    padding: 10;
    z-index: 10;
    top: -2.1875rem;
    right: 1rem;
    width: 4.375rem;
    height: 4.375rem;
    border-radius: 50%;
    svg {
      width: 1.375rem;
      height: 1.375rem;
    }
  }
}
.header-contain {
  height: 100%;
  // background-image: url('../../../../assets/img/home.jpg');
  background-repeat: no-repeat;
  background-size: cover;

  #navbar {
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100%;
    transition: top 0.3s;
  }

  html {
    scroll-behavior: smooth;
  }

  .home-nav-container {
    display: none;

    @media @desktop {
      top: 0;
      display: flex;
      justify-content: right;
      align-items: center;
      height: 2.3rem;
      padding: 0 8rem;
      background: rgba(45, 45, 45, 0.8);
      backdrop-filter: blur(8px);

      ul {
        li {
          font-size: 12px;
          display: inline;
          margin-left: 1.5rem;

          a {
            color: @colors[neutral_1];
          }
        }
      }
    }
  }

  &::before {
    background-color: rgba(0, 0, 0, 0.6);
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
  }

  header {
    background: rgba(20, 20, 20, 0.2);
    backdrop-filter: blur(8px);
    padding: 1rem 20px;

    @media @tablet {
      padding: 2rem 8rem;
    }

    ul {
      background-color: inherit;
      border: none;
    }

    .ant-menu-item::after,
    .ant-menu-submenu::after {
      content: none;
    }

    .ant-menu-item-active:hover {
      background-color: inherit;
    }

    .ant-menu-item span {
      padding-left: 0;
      color: #ffff;
    }

    .home-nav-items {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .group-nav-items {
        display: flex;

        @media @desktop {
          flex-direction: row-reverse;
        }

        .nav-container-buttons {
          display: flex;
          margin-left: auto;
          margin-right: 1rem;

          .talk-button {
            display: none;

            span {
              svg {
                width: 1rem;
                height: 1rem;
                margin-top: 0.225rem;
              }
            }
          }

          // .dropdown-header {
          //   display: none;
          // }

          @media @tablet {
            margin-right: 1rem;
          }

          @media @desktop {
            margin-right: 0;
          }

          @media @tablet {
            .talk-button {
              display: flex;
              margin-right: 0.5rem;
            }
          }
        }

        .container-menu {
          display: flex;
          align-items: center;

          .container-navlinks {
            a {
              color: @colors[neutral_1];
              #typography.body;
              margin-right: 0.5rem;

              @media @desktop {
                margin-right: 1.5rem;
              }
            }
          }
        }
      }
    }
  }
}
.splide__arrow svg {
  fill: #fff !important;
  height: 1.2em;
  width: 1.2em;
}

.splide__pagination__page.is-active {
  background-color: #ff3200 !important;
  width: 15px !important;
  border-radius: 100px !important;
  margin-top: 20px !important;
}

.splide__pagination__page {
  margin-top: 20px !important;
}

.arrow {
  .splide__arrow {
    display: none;
  }
}

@primary-color: #ff5f00;@font-family: Montserrat;@input-height-base: 38px;@line-height-base: 32px;