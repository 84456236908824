@import "../../../../assets/css/vars.less";
@import "../../../../assets/css/media-queries.less";

.container-footer {
  position: relative;
  background-color: @colors[neutral_11];
  padding: 0 1rem;

  @media @tablet {
    padding: 0;
  }

  .contain {
    // margin-top: 4.5rem;

    @media @tablet {
      padding: 0 8rem;
    }

    @media @desktop {
      display: flex;
      justify-content: space-between;
    }

    h3,
    h5 {
      color: @colors[neutral_1];
      margin: 0;
    }

    a,
    p {
      font-size: 14px;
      margin: 0;
      color: @colors[neutral_6];
    }

    .footer-header {
      // margin-top: 4.5rem;

      .footer-social-networks {
        margin-top: 1rem;

        ul {
          li {
            border-radius: 50%;
            width: 2rem;
            height: 2rem;
            background-color: @colors[secondary];
            display: inline-block;
            margin-right: 0.5rem;

            a {
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;

              svg {
                color: @colors[neutral_1];
              }
            }
          }
        }
      }

      .footer-bh-logo {
        margin-top: 1.5rem;
      }
    }

    .footer-about {
      margin-top: 2.5rem;

      @media @desktop {
        margin-top: 0;
      }
    }

    .footer-information {
      margin-top: 2rem;

      @media @desktop {
        margin-top: 0;
      }
    }

    .footer-callcenter {
      margin-top: 2rem;

      @media @desktop {
        margin-top: 0;
      }

      .phone {
        display: flex;
        align-items: center;

        span {
          margin-right: 0.625;

          svg {
            color: @colors[primary];
          }
        }
      }
    }

    .footer-newsletter {
      margin-top: 2rem;
      margin-bottom: 5.5rem;

      @media @desktop {
        margin: 0;
      }

      form {
        margin-top: 0.5rem;
        display: flex;
        flex-direction: column;

        input {
          border-radius: 0.375rem;
          width: 16rem;
        }

        button {
          margin-top: 1rem;
          width: 8rem;
        }
      }
    }
  }

  .footer-signature {
    position: relative;
    font-size: 12px;
    color: @colors[neutral_5];
    margin-bottom: 4.375rem;

    @media @desktop {
      margin-top: 3.5rem;
    }

    hr {
      border: none;
      border-bottom: 1px solid @colors[neutral_5];
      background-color: transparent;
      @media @tablet {
        position: absolute;
        top: 0;
        width: 100%;
        height: 1px;
      }
    }

    .signature-contain {
      @media @desktop {
        display: flex;
        justify-content: space-between;
        padding: 0 8rem;
      }

      .footer-links {
        margin-top: 1rem;
        @media @desktop {
          margin-top: 1.5rem;
        }
        a {
          color: @colors[neutral_5];
          font-size: 12px;
        }

        .ant-divider {
          border-color: @colors[neutral_5];
        }
      }

      .all-rights-reserved {
        font-size: 12px;

        @media @desktop {
          margin-top: 1.5rem;
        }
      }
    }
  }

  .arrow-up-button {
    position: absolute;
    padding: 10;
    z-index: 10;
    top: -2.1875rem;
    right: 1rem;
    width: 4.375rem;
    height: 4.375rem;
    border-radius: 50%;
    svg {
      width: 1.375rem;
      height: 1.375rem;
    }
  }
}

@primary-color: #ff5f00;@font-family: Montserrat;@input-height-base: 38px;@line-height-base: 32px;